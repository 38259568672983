/**
 * Determines whether the given string contains any multi-byte characters.
 * Multi-byte characters are identified by their Unicode surrogate pairs in the range of U+D800 to U+DFFF.
 *
 * @param {string} v - The string to be tested for multi-byte characters.
 * @returns {boolean} - `true` if the string contains any multi-byte characters; otherwise, `false`.
 */
export const hasMultiByteCharacters = (v: string): boolean => {
  return /[\uD800-\uDFFF]/.test(v);
};
