<template>
  <div class="sheet-form">
    <v-form ref="ticketForm" v-model="valid" lazy-validation>
      <div class="form-main-header">
        <h1>New Ticket</h1>
        <v-divider v-if="!submitting" />
      </div>
      <v-layout sheet wrap>
        <br v-if="!submitting" />
        <v-flex v-if="submitting" xs12>
          <v-progress-linear :indeterminate="true" height="1" />
        </v-flex>

        <v-flex offset-xs4 xs4>
          <label
            :class="testIsRequired(ticket.type) ? '' : 'form-error'"
            for="ticketType"
          >
            Ticket Type
          </label>
          <CRSelect
            v-model="ticket.type"
            :items="ticketTypes"
            :outline="!testIsRequired(ticket.type)"
            :rules="isRequired('Ticket Type Required')"
            flat
            item-text="label"
            item-value="id"
            solo
          />
        </v-flex>

        <v-flex offset-xs4 xs4>
          <label
            :class="testIsRequired(ticket.title) ? '' : 'form-error'"
            for="ticketTitle"
          >
            Ticket Title
          </label>
          <v-text-field
            id="ticketTitle"
            v-model="ticket.title"
            :outline="!testIsRequired(ticket.title)"
            :rules="
              [].concat(
                isRequired('Ticket Title Required'),
                hasInvalidCharacters(
                  'Contains invalid characters, emojis aren\'t allowed'
                )
              )
            "
            flat
            solo
          />
        </v-flex>

        <v-flex offset-xs4 xs4>
          <label
            :class="testIsRequired(ticket.severity) ? '' : 'form-error'"
            for="ticketSeverity"
          >
            Severity
          </label>
          <CRSelect
            v-model="ticket.severity"
            :items="severityLevels"
            :outline="!testIsRequired(ticket.severity)"
            :rules="isRequired('Ticket Severity Required')"
            flat
            item-text="label"
            item-value="id"
            return-object
            solo
          >
            <template #selection="{ item }">
              <CRIcon v-if="item.id === 1" :width="16">extreme</CRIcon>
              <CRIcon v-if="item.id === 2" :width="16">high</CRIcon>
              <CRIcon v-if="item.id === 3" :width="16">medium</CRIcon>
              <CRIcon v-if="item.id === 4" :width="16">low</CRIcon>
              <span style="padding-left: 12px">{{ item.label }}</span>
            </template>
          </CRSelect>
        </v-flex>

        <v-flex offset-xs4 xs4>
          <AutoCompleteUser
            label="Assignee"
            are-ticket-users
            @user-cleared="userCleared"
            @user-selected="userSelected"
          />
        </v-flex>

        <v-flex offset-xs4 xs4>
          <label
            :class="
              noMatchingReservation && ticket.managedId ? 'form-error' : ''
            "
            for="managedId"
          >
            Reservation Number
          </label>
          <v-text-field
            id="managedId"
            v-model="ticket.managedId"
            :outline="noMatchingReservation && ticket.managedId"
            :rules="
              noMatchingReservation && ticket.managedId
                ? [].concat(
                    ['Reservation Not Found'],
                    isRequired('reservation number required')
                  )
                : [].concat(
                    [true],
                    isRequired('reservation number is required')
                  )
            "
            placeholder="Ex. 123456, or 123456-1"
            flat
            solo
            @blur="clearReservationValidation"
          />
        </v-flex>

        <v-flex v-if="isOperatorCancelTicket" offset-xs4 xs4>
          <label
            :class="
              noMatchingReferral && ticket.referralId ? 'form-error' : ''
            "
            for="referralId"
          >
            Referral Number
          </label>
          <v-text-field
            id="referralId"
            v-model="ticket.referralId"
            :outline="noMatchingReferral && ticket.referralId"
            :rules="
              noMatchingReferral && ticket.referralId
                ? [].concat(
                    ['Referral Not Found'],
                    isRequired('referral number required')
                  )
                : [].concat(
                    [true],
                    isRequired('referral number is required')
                  )
            "
            placeholder="Ex. 123456.1, or 123456-1.1"
            flat
            solo
            @blur="clearReferralValidation"
          />
        </v-flex>

        <v-flex offset-xs4 xs4>
          <label
            :class="testIsRequired(ticket.comments) ? '' : 'form-error'"
            for="comments"
          >
            Comments
          </label>
          <v-textarea
            id="comments"
            v-model="ticket.comments"
            :outline="!testIsRequired(ticket.comments)"
            :rules="
              [].concat(
                isRequired('Comments Required'),
                hasInvalidCharacters(
                  'Contains invalid characters, emojis aren\'t allowed'
                )
              )
            "
            flat
            solo
          />
        </v-flex>

        <v-flex text-xs-center xs12>
          <v-btn class="btn-secondaryaction" @click="backToTable">Cancel</v-btn>
          <v-btn
            :color="valid ? '' : 'error'"
            class="btn-primaryaction"
            :loading="submitting"
            :disabled="submitting"
            @click="submit"
          >
            Add Ticket
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { hasMultiByteCharacters } from '@/utils/hasMultiByteCharacters'
import { authComputed } from '@/state/helpers'
import AutoCompleteUser from '@/components/AutoCompleteUser.vue'
import { reservationById } from '@/services/reservations'
import ticket from '@/services/ticket'

export default {
  name: 'TicketsForm',
  components: { AutoCompleteUser },
  metaInfo() {
    return {
      title: 'Ticket',
    }
  },
  props: {
    reservationId: {
      type: [Number, String],
      default: () => null,
    },
    managedId: {
      type: [Number, String],
      default: () => null,
    },
  },
  data() {
    return {
      OPERATOR_CANCELLATION_TICKET_TYPE_ID: 12,
      valid: true,
      noMatchingReservation: false,
      noMatchingReferral: false,
      ticket: {},
      ticketTypes: [],
      severityLevels: [],
      submitting: false,
      row: {},
      layout: [],
      formType: 'Tickets',
    }
  },
  computed: {
    ...authComputed,
    isOperatorCancelTicket() {
      return this.ticket.type === this.OPERATOR_CANCELLATION_TICKET_TYPE_ID
    }
  },
  async mounted() {
    const ticketTypeData = await this.$store.dispatch('types/getTicketTypes')
    const result = await this.availableTicketTypes()

    this.ticketTypes = ticketTypeData.data.filter((t) => result.includes(t.key))

    const severityTypeData = await this.$store.dispatch('types/getTicketLevels')
    this.severityLevels = severityTypeData.data

    if (this.managedId) {
      this.ticket.managedId = this.managedId
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
      availableTicketTypes: 'split/getAvailableTicketTypes',
    }),
    async submit() {
      this.submitting = true
      this.valid = this.$refs.ticketForm.validate()
      if (this.valid) {
        await this.createNewTicket()
      }
      this.submitting = false
    },

    async createNewTicket() {
      const user = this.currentUser
      const newticket = {
        createdById: user.userId,
        companyId: user.companyId,
        ticketSeverityTypeId: this.ticket.severity.id,
        ticketStatusTypeId: '1', // denotes 'created' status
        ticketTypeId: this.ticket.type,
        title: this.ticket.title,
        comments: this.ticket.comments,
        createdAt: new Date(),
      }

      if (this.ticket.assignedToId) {
        newticket.assignedToId = this.ticket.assignedToId
      }

      if (this.ticket.managedId) {
        const { data: matchedReservation } = await reservationById(
          this.ticket.managedId
        ).catch(() => {
          return { data: {} }
        })

        if (!matchedReservation.reservationId) {
          this.noMatchingReservation = true
          return
        }

        if (this.ticket.referralId) {
          if (!matchedReservation.referredTo) {
            this.noMatchingReferral = true
            return
          }

          const matchedReferral = matchedReservation.referredTo.find(referral =>
            referral.managedId === this.ticket.referralId
          )

          if (!matchedReferral) {
            this.noMatchingReferral = true
            return
          }

          newticket.referralId = matchedReferral.reservationId
        }

        newticket.reservationId = matchedReservation.reservationId
      }

      try {
        const response = await ticket.create({
          payload: newticket,
        })
        if (newticket.ticketTypeId === 1) {
          await this.$store.dispatch('reservations/cancelReservation', {
            reservationId: newticket.reservationId,
            sendEmail: false,
            classificationId: null,
          })
        }
        if (response.data == -1) {
          throw 'Reinstatement failure'
        } else {
          this.$store.dispatch(
            'app/showAlert',
            { message: 'A ticket has been created.' },
            { root: true }
          )
        }
      } catch (error) {
        this.displayWarning = true
        this.warningMessage = error
        this.showAlert({
          message: 'There was an error saving this ticket.',
          type: 'error',
        })

        return
      }

      if (this.isRA) {
        this.$router.push({
          name: 'reservation-detail',
          params: { id: this.ticket.managedId },
        })
      } else {
        this.$router.push('/tickets')
      }
      this.showAlert({
        message: 'Ticket saved.',
        type: 'success',
      })
    },
    clearReservationValidation() {
      this.noMatchingReservation = false
    },
    clearReferralValidation() {
      this.noMatchingReferral = false
    },
    testIsRequired(value) {
      const validationFunctions = this.isRequired(value)
      const safeExecution = (validationFunction) => {
        try {
          return validationFunction(value)
        } catch (e) {
          return false
        }
      }
      return validationFunctions.every(safeExecution)
    },
    hasInvalidCharacters(message) {
      return [(value) => !hasMultiByteCharacters(value) || message]
    },
    isRequired(message) {
      const isEmpty = (value) => {
        if (!this.submitting) {
          return true
        }
        if (typeof value === 'undefined') {
          return false
        }
        if (typeof value === 'string') {
          return value.length > 0
        }
        if (['number', 'boolean'].includes(typeof value)) {
          return true
        }
        if (value instanceof Date) {
          return true
        }
        if (value.id) {
          return true
        }
        return false
      }
      return [(value) => isEmpty(value) || message]
    },
    userCleared() {
      this.ticket.assignedToId = null
    },
    userSelected(staffObject) {
      this.ticket.assignedToId = staffObject.userId
    },
    backToTable() {
      this.$router.push('/tickets')
    },
  },
}
</script>

<style lang="scss" scoped>
.cr-base-form-display-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 20%;
  margin-bottom: 50%;
}

.form-error {
  color: $red;
}
</style>
<style scoped>
::v-deep .width-30 {
  max-width: 421px;
}

::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0;
}
</style>
