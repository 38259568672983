var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sheet-form"},[_c('v-form',{ref:"ticketForm",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"form-main-header"},[_c('h1',[_vm._v("New Ticket")]),(!_vm.submitting)?_c('v-divider'):_vm._e()],1),_c('v-layout',{attrs:{"sheet":"","wrap":""}},[(!_vm.submitting)?_c('br'):_vm._e(),(_vm.submitting)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-progress-linear',{attrs:{"indeterminate":true,"height":"1"}})],1):_vm._e(),_c('v-flex',{attrs:{"offset-xs4":"","xs4":""}},[_c('label',{class:_vm.testIsRequired(_vm.ticket.type) ? '' : 'form-error',attrs:{"for":"ticketType"}},[_vm._v(" Ticket Type ")]),_c('CRSelect',{attrs:{"items":_vm.ticketTypes,"outline":!_vm.testIsRequired(_vm.ticket.type),"rules":_vm.isRequired('Ticket Type Required'),"flat":"","item-text":"label","item-value":"id","solo":""},model:{value:(_vm.ticket.type),callback:function ($$v) {_vm.$set(_vm.ticket, "type", $$v)},expression:"ticket.type"}})],1),_c('v-flex',{attrs:{"offset-xs4":"","xs4":""}},[_c('label',{class:_vm.testIsRequired(_vm.ticket.title) ? '' : 'form-error',attrs:{"for":"ticketTitle"}},[_vm._v(" Ticket Title ")]),_c('v-text-field',{attrs:{"id":"ticketTitle","outline":!_vm.testIsRequired(_vm.ticket.title),"rules":[].concat(
              _vm.isRequired('Ticket Title Required'),
              _vm.hasInvalidCharacters(
                'Contains invalid characters, emojis aren\'t allowed'
              )
            ),"flat":"","solo":""},model:{value:(_vm.ticket.title),callback:function ($$v) {_vm.$set(_vm.ticket, "title", $$v)},expression:"ticket.title"}})],1),_c('v-flex',{attrs:{"offset-xs4":"","xs4":""}},[_c('label',{class:_vm.testIsRequired(_vm.ticket.severity) ? '' : 'form-error',attrs:{"for":"ticketSeverity"}},[_vm._v(" Severity ")]),_c('CRSelect',{attrs:{"items":_vm.severityLevels,"outline":!_vm.testIsRequired(_vm.ticket.severity),"rules":_vm.isRequired('Ticket Severity Required'),"flat":"","item-text":"label","item-value":"id","return-object":"","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [(item.id === 1)?_c('CRIcon',{attrs:{"width":16}},[_vm._v("extreme")]):_vm._e(),(item.id === 2)?_c('CRIcon',{attrs:{"width":16}},[_vm._v("high")]):_vm._e(),(item.id === 3)?_c('CRIcon',{attrs:{"width":16}},[_vm._v("medium")]):_vm._e(),(item.id === 4)?_c('CRIcon',{attrs:{"width":16}},[_vm._v("low")]):_vm._e(),_c('span',{staticStyle:{"padding-left":"12px"}},[_vm._v(_vm._s(item.label))])]}}]),model:{value:(_vm.ticket.severity),callback:function ($$v) {_vm.$set(_vm.ticket, "severity", $$v)},expression:"ticket.severity"}})],1),_c('v-flex',{attrs:{"offset-xs4":"","xs4":""}},[_c('AutoCompleteUser',{attrs:{"label":"Assignee","are-ticket-users":""},on:{"user-cleared":_vm.userCleared,"user-selected":_vm.userSelected}})],1),_c('v-flex',{attrs:{"offset-xs4":"","xs4":""}},[_c('label',{class:_vm.noMatchingReservation && _vm.ticket.managedId ? 'form-error' : '',attrs:{"for":"managedId"}},[_vm._v(" Reservation Number ")]),_c('v-text-field',{attrs:{"id":"managedId","outline":_vm.noMatchingReservation && _vm.ticket.managedId,"rules":_vm.noMatchingReservation && _vm.ticket.managedId
              ? [].concat(
                  ['Reservation Not Found'],
                  _vm.isRequired('reservation number required')
                )
              : [].concat(
                  [true],
                  _vm.isRequired('reservation number is required')
                ),"placeholder":"Ex. 123456, or 123456-1","flat":"","solo":""},on:{"blur":_vm.clearReservationValidation},model:{value:(_vm.ticket.managedId),callback:function ($$v) {_vm.$set(_vm.ticket, "managedId", $$v)},expression:"ticket.managedId"}})],1),(_vm.isOperatorCancelTicket)?_c('v-flex',{attrs:{"offset-xs4":"","xs4":""}},[_c('label',{class:_vm.noMatchingReferral && _vm.ticket.referralId ? 'form-error' : '',attrs:{"for":"referralId"}},[_vm._v(" Referral Number ")]),_c('v-text-field',{attrs:{"id":"referralId","outline":_vm.noMatchingReferral && _vm.ticket.referralId,"rules":_vm.noMatchingReferral && _vm.ticket.referralId
              ? [].concat(
                  ['Referral Not Found'],
                  _vm.isRequired('referral number required')
                )
              : [].concat(
                  [true],
                  _vm.isRequired('referral number is required')
                ),"placeholder":"Ex. 123456.1, or 123456-1.1","flat":"","solo":""},on:{"blur":_vm.clearReferralValidation},model:{value:(_vm.ticket.referralId),callback:function ($$v) {_vm.$set(_vm.ticket, "referralId", $$v)},expression:"ticket.referralId"}})],1):_vm._e(),_c('v-flex',{attrs:{"offset-xs4":"","xs4":""}},[_c('label',{class:_vm.testIsRequired(_vm.ticket.comments) ? '' : 'form-error',attrs:{"for":"comments"}},[_vm._v(" Comments ")]),_c('v-textarea',{attrs:{"id":"comments","outline":!_vm.testIsRequired(_vm.ticket.comments),"rules":[].concat(
              _vm.isRequired('Comments Required'),
              _vm.hasInvalidCharacters(
                'Contains invalid characters, emojis aren\'t allowed'
              )
            ),"flat":"","solo":""},model:{value:(_vm.ticket.comments),callback:function ($$v) {_vm.$set(_vm.ticket, "comments", $$v)},expression:"ticket.comments"}})],1),_c('v-flex',{attrs:{"text-xs-center":"","xs12":""}},[_c('v-btn',{staticClass:"btn-secondaryaction",on:{"click":_vm.backToTable}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"color":_vm.valid ? '' : 'error',"loading":_vm.submitting,"disabled":_vm.submitting},on:{"click":_vm.submit}},[_vm._v(" Add Ticket ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }